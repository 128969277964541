import React from 'react';
import Image from 'gatsby-image';
import {GitHub, Phone, Email, Language, LocationOn, LinkedIn} from '@material-ui/icons';


const Profile = ({ name, imageData, intro }) => (
  <div className="profile">
    <div className="profile-image" >
      <Image fluid={imageData} alt={name} />
      <div className="attributes">
        <Phone style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="tel:+44 7747-847-739">
          <span className="attributes">+44 7747-847-739</span>
        </a>
      </div>
      <div className="attributes">
        <Email style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="mailto:greg@kooji.com">
          <span className="attributes">greg@kooji.com</span>
        </a>
      </div>
      <div className="attributes">
        <Language style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="https://kooji.com">
          <span className="attributes">kooji.com</span>
        </a>
      </div>
      <div className="attributes">
        <LinkedIn style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="https://www.linkedin.com/in/greg-king-0167311/">
          <span className="attributes">LinkedIn</span>
        </a>
      </div>
      <div className="attributes">
        <GitHub style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="https://github.com/gregoryking">
          <span className="attributes">gregoryking</span>
        </a>
      </div>
      <div className="attributes">
        <LocationOn style={{ color: '#FF3333', 'vertical-align': 'middle' }}/>
        <a href="https://goo.gl/maps/wZgDjZPxcjUWLf426">
          <span className="attributes">Surrey, UK</span>
        </a>
      </div>
    </div>
    <div className="profile-text">
      <h1>{name}</h1>
      <p>{intro}</p>
    </div>
  </div>
);

export default Profile;
