import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import ProjectPreview from '../components/project-preview';
import Profile from '../components/profile';

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            title
            slug
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allProfileJson {
        edges {
          node {
            name
            intro
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
    
            }
          }
        }
      }
    }
  `);

  const projects = data.allProjectsJson.edges;

  const profile = data.allProfileJson.edges[0].node

  console.log(profile);

  return (
    <Layout>
      <Profile 
        name={profile.name}
        imageData={profile.image.childImageSharp.fluid}
        intro={profile.intro}/>
      <h2>Recent Projects</h2>
      <br/>
      <br/>
      <div className="projects">
        {projects.map(({ node: project }) => {
          const title = project.title;
          const description = project.description;
          const slug = project.slug;
          const imageData = project.image.childImageSharp.fluid;
          return (
              <ProjectPreview
                title={title}
                description={description}
                slug={slug}
                imageData={imageData}
              />
          );
        })}
      </div>
    </Layout>
  );
};

export default Home;
